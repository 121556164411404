import React from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"


function chartOptions(data) {
    return {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: 'Accessibility Issues by Category'
        },
        tooltip: {
            enabled: false
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {

                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        series: [{
            name: 'Category',
            colorByPoint: true,
            data: data
        }]
    }
}
const table = (data) => {
    return (<table className="table table-sm"><thead><tr scope="col"><td>Category</td><td scope="col">Issue Count</td></tr></thead><tbody>
        {data.map(o => { return (<tr><th scope="row">{o.name}</th><td>{o.y}</td></tr> )})}</tbody></table>)
}
const IssueChart = (data) => {
    console.log(data["data"])
    return (
        <div className="row">
            <div className="col-12 col-lg-7"><HighchartsReact options={chartOptions(data.data)} highcharts={Highcharts} /></div>
            <div className="col-12 col-lg-5">{ data["data"] ? table(data["data"]) : "There were no issues"}</div>
        </div>
    )
}

export default IssueChart