import React from "react"

export default function(width, height) {
    return (
<svg width={width} height={height} viewBox="0 0 483 157" fill="none" xmlns="http://www.w3.org/2000/svg">
  <title>Home</title>
  <path d="M48.2666 31.127H60.2314L78.1784 80.8209H66.7576L63.3585 70.5817H44.7316L41.3326 80.8209H30.3196L48.2666 31.127ZM47.5868 61.9809H60.5033L54.113 42.4583L47.5868 61.9809Z" fill="#23376F"></path>
  <path d="M81.8494 31.127H118.015V39.8643H92.3185V51.3322H114.752V59.933H92.3185V80.8209H81.8494V31.127Z" fill="#23376F"></path>
  <path d="M159.892 36.4513C161.387 38.4991 162.203 41.093 162.203 44.0965C162.203 47.1 161.387 49.5574 159.892 51.4687C159.076 52.5609 157.716 53.38 156.085 54.3357C158.668 55.2913 160.571 56.6565 161.931 58.7043C163.291 60.7522 163.97 63.073 163.97 65.94C163.97 68.9435 163.155 71.5374 161.659 73.8583C160.707 75.36 159.484 76.7252 158.124 77.8174C156.493 79.0461 154.589 79.8652 152.414 80.2748C150.238 80.6843 147.791 80.9574 145.208 80.9574H122.23V31.127H146.703C152.958 31.2635 157.308 32.9017 159.892 36.4513ZM132.291 39.7278V50.6496H144.664C146.839 50.6496 148.607 50.24 149.966 49.4209C151.326 48.6017 152.006 47.1 152.006 45.0522C152.006 42.7313 151.054 41.2296 149.287 40.4104C147.655 39.8643 145.752 39.5913 143.304 39.5913H132.291V39.7278ZM132.291 58.9774V72.22H144.664C146.839 72.22 148.607 71.947 149.83 71.4009C152.006 70.3087 153.229 68.2609 153.229 65.2574C153.229 62.6635 152.142 60.8887 149.966 59.933C148.743 59.387 147.111 59.1139 144.936 59.1139H132.291V58.9774V58.9774Z" fill="#23376F"></path>
  <path d="M180.422 31.5365H183.685L191.571 53.38H188.308L185.997 46.8269H177.431L175.12 53.38H172.128L180.422 31.5365ZM185.317 44.5061L181.918 34.813L178.383 44.5061H185.317Z" fill="#23376F"></path>
  <path d="M193.338 37.5435H195.922V39.8644C196.601 39.0452 197.145 38.4991 197.689 38.2261C198.505 37.68 199.593 37.2704 200.68 37.2704C201.904 37.2704 202.992 37.5435 203.672 38.2261C204.079 38.6357 204.487 39.0452 204.895 39.8644C205.439 39.0452 206.119 38.3626 206.935 37.9531C207.75 37.5435 208.566 37.2704 209.654 37.2704C211.829 37.2704 213.189 38.0896 214.005 39.5913C214.413 40.4104 214.684 41.5026 214.684 43.0044V53.5165H211.965V42.3217C211.965 41.2296 211.693 40.547 211.149 40.1374C210.606 39.7278 209.926 39.5913 209.246 39.5913C208.158 39.5913 207.343 40.0009 206.527 40.6835C205.711 41.3661 205.439 42.5948 205.439 44.2331V53.5165H202.72V43.1409C202.72 42.0487 202.584 41.2296 202.312 40.82C201.904 40.1374 201.088 39.7278 200.001 39.7278C199.049 39.7278 198.097 40.1374 197.281 40.9565C196.466 41.7757 196.058 43.1409 196.058 45.1887V53.653H193.338V37.5435Z" fill="#23376F"></path>
  <path d="M227.737 37.953C228.825 38.4991 229.64 39.1817 230.184 40.0009C230.728 40.82 231 41.7756 231.272 42.8678C231.408 43.5504 231.544 44.7791 231.544 46.4174H219.987C219.987 48.0556 220.395 49.2843 221.075 50.24C221.755 51.1956 222.842 51.7417 224.338 51.7417C225.697 51.7417 226.785 51.3322 227.601 50.3765C228.009 49.8304 228.417 49.2843 228.553 48.4652H231.136C231 49.0113 230.864 49.6939 230.456 50.3765C230.048 51.0591 229.64 51.7417 229.097 52.1513C228.281 52.9704 227.329 53.5165 226.105 53.7895C225.426 53.9261 224.746 54.0626 223.93 54.0626C222.026 54.0626 220.259 53.38 218.899 51.8782C217.54 50.3765 216.86 48.4652 216.86 45.8713C216.86 43.2774 217.54 41.2296 218.899 39.5913C220.259 37.953 222.026 37.2704 224.338 37.2704C225.697 37.1339 226.785 37.4069 227.737 37.953ZM228.689 44.233C228.553 43.1409 228.281 42.1852 228.009 41.5026C227.329 40.2739 226.105 39.5913 224.338 39.5913C223.114 39.5913 222.162 40.0009 221.347 40.9565C220.531 41.7756 220.123 43.0043 219.987 44.3695L228.689 44.233Z" fill="#23376F"></path>
  <path d="M234.399 37.5435H236.983V40.2739C237.255 39.7278 237.663 39.0452 238.478 38.3626C239.294 37.5435 240.246 37.2704 241.334 37.2704C241.334 37.2704 241.469 37.2704 241.605 37.2704C241.741 37.2704 241.877 37.2704 242.149 37.2704V40.1374C242.013 40.1374 241.877 40.1374 241.741 40.1374C241.605 40.1374 241.469 40.1374 241.334 40.1374C239.974 40.1374 239.022 40.547 238.206 41.5026C237.527 42.3217 237.119 43.4139 237.119 44.5061V53.653H234.399V37.5435Z" fill="#23376F"></path>
  <path d="M243.781 31.5365H246.5V34.54H243.781V31.5365ZM243.781 37.5435H246.5V53.38H243.781V37.5435Z" fill="#23376F"></path>
  <path d="M260.504 38.3626C261.592 39.1817 262.272 40.6835 262.544 42.8678H259.96C259.824 41.9122 259.416 41.093 258.873 40.4104C258.329 39.7278 257.377 39.4548 256.153 39.4548C254.522 39.4548 253.298 40.2739 252.482 41.9122C252.074 43.0043 251.802 44.3695 251.802 45.8713C251.802 47.5095 252.074 48.7382 252.754 49.8304C253.434 50.9226 254.522 51.4687 255.881 51.4687C256.969 51.4687 257.921 51.1956 258.465 50.513C259.144 49.8304 259.552 48.8748 259.824 47.7826H262.408C262.136 49.8304 261.32 51.4687 260.232 52.4243C259.008 53.38 257.513 53.9261 255.745 53.9261C253.706 53.9261 252.074 53.1069 250.851 51.6052C249.627 50.1035 248.947 48.1922 248.947 45.8713C248.947 43.1409 249.627 40.9565 250.987 39.3182C252.346 37.8165 254.114 36.9974 256.153 36.9974C257.921 36.9974 259.28 37.5435 260.504 38.3626Z" fill="#23376F"></path>
  <path d="M273.012 43.8235C273.556 43.6869 273.964 43.5504 274.236 43.0043C274.372 42.7313 274.372 42.4582 274.372 41.9122C274.372 40.9565 274.1 40.2739 273.42 39.8643C272.74 39.4548 271.789 39.1817 270.701 39.1817C269.341 39.1817 268.39 39.5913 267.71 40.2739C267.438 40.6835 267.166 41.3661 267.03 42.1852H264.583C264.583 40.2739 265.262 38.9087 266.486 38.0896C267.71 37.2704 269.069 36.9974 270.701 36.9974C272.604 36.9974 274.1 37.4069 275.188 38.0896C276.275 38.7722 276.955 39.8643 276.955 41.3661V50.513C276.955 50.7861 276.955 51.0591 277.091 51.1956C277.227 51.3322 277.499 51.4687 277.771 51.4687C277.907 51.4687 278.043 51.4687 278.179 51.4687C278.315 51.4687 278.451 51.4687 278.587 51.4687V53.38C278.179 53.5165 277.907 53.5165 277.635 53.653C277.363 53.653 277.091 53.653 276.683 53.653C275.732 53.653 275.052 53.38 274.644 52.6974C274.372 52.2878 274.236 51.8782 274.236 51.1956C273.692 51.8782 272.876 52.5609 271.925 53.1069C270.973 53.653 269.749 53.9261 268.526 53.9261C267.03 53.9261 265.806 53.5165 264.99 52.5609C264.039 51.6052 263.631 50.513 263.631 49.1478C263.631 47.6461 264.039 46.5539 264.99 45.7348C265.942 44.9156 267.166 44.3696 268.661 44.233L273.012 43.8235ZM267.438 51.0591C267.982 51.4687 268.661 51.7417 269.477 51.7417C270.429 51.7417 271.381 51.4687 272.196 51.0591C273.692 50.3765 274.372 49.1478 274.372 47.5095V45.3252C274.1 45.5982 273.692 45.7348 273.148 45.8713C272.604 46.0078 272.197 46.1443 271.653 46.1443L270.021 46.4174C269.069 46.5539 268.39 46.6904 267.846 46.9635C267.03 47.373 266.622 48.1922 266.622 49.1478C266.622 49.9669 266.894 50.513 267.438 51.0591Z" fill="#23376F"></path>
  <path d="M280.898 37.5435H283.482V39.8644C284.298 38.9087 284.977 38.2261 285.929 37.8165C286.745 37.407 287.697 37.2704 288.784 37.2704C291.096 37.2704 292.591 38.0896 293.271 39.5913C293.679 40.4104 293.951 41.6391 293.951 43.2774V53.5165H291.232V43.5504C291.232 42.5948 291.096 41.7757 290.824 41.2296C290.416 40.2739 289.464 39.7278 288.24 39.7278C287.561 39.7278 287.153 39.7278 286.745 39.8644C286.065 40.1374 285.385 40.547 284.841 41.2296C284.433 41.7757 284.162 42.3217 284.026 42.8678C283.89 43.4139 283.89 44.233 283.89 45.3252V53.653H281.17V37.5435H280.898Z" fill="#23376F"></path>
  <path d="M305.644 31.5365H320.735V34.2669H308.635V40.82H319.24V43.4139H308.635V53.38H305.644V31.5365Z" fill="#23376F"></path>
  <path d="M334.468 39.0452C335.827 40.4104 336.507 42.4583 336.507 45.0522C336.507 47.6461 335.827 49.6939 334.604 51.3322C333.38 52.9704 331.477 53.7896 328.893 53.7896C326.718 53.7896 324.95 53.107 323.727 51.6052C322.503 50.1035 321.823 48.1922 321.823 45.5983C321.823 42.8678 322.503 40.82 323.863 39.1817C325.222 37.5435 326.99 36.8609 329.301 36.8609C331.341 36.9974 332.972 37.68 334.468 39.0452ZM332.7 49.6939C333.38 48.3287 333.652 46.827 333.652 45.1887C333.652 43.687 333.38 42.4583 332.972 41.6391C332.292 40.1374 330.933 39.4548 329.165 39.4548C327.534 39.4548 326.446 40.1374 325.63 41.3661C324.814 42.5948 324.543 44.0965 324.543 45.8713C324.543 47.5096 324.95 49.0113 325.63 50.1035C326.31 51.1957 327.534 51.7417 329.165 51.7417C330.933 51.6052 332.156 50.9226 332.7 49.6939Z" fill="#23376F"></path>
  <path d="M341.946 37.5435V48.0556C341.946 48.8748 342.082 49.5574 342.353 50.1035C342.761 51.0591 343.713 51.4687 344.937 51.4687C346.704 51.4687 347.928 50.6495 348.608 49.0113C349.016 48.0556 349.152 46.9635 349.152 45.3252V37.5435H351.871V53.38H349.424V51.0591C349.152 51.6052 348.608 52.1513 348.2 52.5608C347.248 53.38 346.024 53.7895 344.529 53.7895C342.353 53.7895 340.858 52.9704 340.042 51.4687C339.634 50.6495 339.362 49.5574 339.362 48.1922V37.4069H341.946V37.5435V37.5435Z" fill="#23376F"></path>
  <path d="M355.542 37.5435H358.125V39.8644C358.941 38.9087 359.621 38.2261 360.573 37.8165C361.388 37.407 362.34 37.2704 363.428 37.2704C365.739 37.2704 367.235 38.0896 367.915 39.5913C368.322 40.4104 368.594 41.6391 368.594 43.2774V53.5165H365.875V43.5504C365.875 42.5948 365.739 41.7757 365.467 41.2296C365.059 40.2739 364.108 39.7278 362.884 39.7278C362.204 39.7278 361.796 39.7278 361.388 39.8644C360.709 40.1374 360.029 40.547 359.485 41.2296C359.077 41.7757 358.805 42.3217 358.669 42.8678C358.533 43.4139 358.533 44.233 358.533 45.3252V53.653H355.814V37.5435H355.542Z" fill="#23376F"></path>
  <path d="M380.423 37.953C380.831 38.2261 381.375 38.7722 382.055 39.4548V31.4H384.638V53.2435H382.191V51.0591C381.511 52.0148 380.831 52.6974 380.015 53.2435C379.199 53.7896 378.248 53.9261 377.16 53.9261C375.392 53.9261 373.897 53.2435 372.537 51.7417C371.314 50.24 370.634 48.3287 370.634 45.7348C370.634 43.4139 371.178 41.3661 372.401 39.7278C373.625 38.0896 375.257 37.1339 377.432 37.1339C378.656 37.2704 379.607 37.5435 380.423 37.953ZM374.577 49.9669C375.257 51.0591 376.48 51.7417 377.976 51.7417C379.199 51.7417 380.151 51.1956 380.967 50.1035C381.783 49.0113 382.191 47.5096 382.191 45.4617C382.191 43.4139 381.783 41.9122 380.967 40.9565C380.151 40.0009 379.199 39.5913 377.976 39.5913C376.616 39.5913 375.528 40.1374 374.713 41.093C373.897 42.1852 373.489 43.6869 373.489 45.5983C373.489 47.373 373.897 48.7382 374.577 49.9669Z" fill="#23376F"></path>
  <path d="M396.603 43.8235C397.146 43.6869 397.69 43.5504 397.826 43.0043C397.962 42.7313 397.962 42.4582 397.962 41.9122C397.962 40.9565 397.69 40.2739 397.011 39.8643C396.331 39.4548 395.379 39.1817 394.291 39.1817C392.932 39.1817 391.98 39.5913 391.3 40.2739C391.028 40.6835 390.756 41.3661 390.62 42.1852H388.173C388.173 40.2739 388.853 38.9087 390.076 38.0896C391.3 37.2704 392.66 36.9974 394.291 36.9974C396.195 36.9974 397.69 37.4069 398.778 38.0896C399.866 38.7722 400.546 39.8643 400.546 41.3661V50.513C400.546 50.7861 400.546 51.0591 400.682 51.1956C400.817 51.3322 401.089 51.4687 401.361 51.4687C401.497 51.4687 401.633 51.4687 401.769 51.4687C401.905 51.4687 402.041 51.4687 402.177 51.4687V53.38C401.769 53.5165 401.497 53.5165 401.225 53.653C400.953 53.653 400.681 53.653 400.274 53.653C399.322 53.653 398.642 53.38 398.234 52.6974C397.962 52.2878 397.826 51.8782 397.826 51.1956C397.282 51.8782 396.467 52.5609 395.515 53.1069C394.563 53.653 393.34 53.9261 392.116 53.9261C390.62 53.9261 389.397 53.5165 388.581 52.5609C387.629 51.6052 387.221 50.513 387.221 49.1478C387.221 47.6461 387.629 46.5539 388.581 45.7348C389.533 44.9156 390.756 44.3696 392.252 44.233L396.603 43.8235ZM390.892 51.0591C391.436 51.4687 392.116 51.7417 392.932 51.7417C393.883 51.7417 394.835 51.4687 395.651 51.0591C397.146 50.3765 397.826 49.1478 397.826 47.5095V45.3252C397.554 45.5982 397.146 45.7348 396.603 45.8713C396.059 46.0078 395.651 46.1443 395.107 46.1443L393.475 46.4174C392.524 46.5539 391.844 46.6904 391.3 46.9635C390.484 47.373 390.076 48.1922 390.076 49.1478C390.076 49.9669 390.348 50.513 390.892 51.0591Z" fill="#23376F"></path>
  <path d="M404.897 33.0382H407.616V37.4069H410.199V39.5913H407.616V49.9669C407.616 50.513 407.752 50.9226 408.16 51.0591C408.432 51.1956 408.704 51.1956 409.247 51.1956C409.383 51.1956 409.519 51.1956 409.655 51.1956C409.791 51.1956 409.927 51.1956 410.199 51.1956V53.38C409.927 53.5165 409.519 53.5165 409.247 53.5165C408.975 53.5165 408.568 53.5165 408.16 53.5165C406.936 53.5165 406.12 53.2435 405.576 52.5608C405.169 51.8782 404.897 51.0591 404.897 50.1035V39.5913H402.721V37.4069H404.897V33.0382Z" fill="#23376F"></path>
  <path d="M412.238 31.5365H414.958V34.54H412.238V31.5365ZM412.238 37.5435H414.958V53.38H412.238V37.5435Z" fill="#23376F"></path>
  <path d="M429.914 39.0452C431.273 40.4104 431.953 42.4583 431.953 45.0522C431.953 47.6461 431.273 49.6939 430.05 51.3322C428.826 52.9704 426.922 53.7896 424.339 53.7896C422.164 53.7896 420.396 53.107 419.173 51.6052C417.949 50.1035 417.269 48.1922 417.269 45.5983C417.269 42.8678 417.949 40.82 419.308 39.1817C420.668 37.5435 422.436 36.8609 424.747 36.8609C426.786 36.9974 428.418 37.68 429.914 39.0452ZM428.146 49.6939C428.826 48.3287 429.098 46.827 429.098 45.1887C429.098 43.687 428.826 42.4583 428.418 41.6391C427.738 40.1374 426.379 39.4548 424.611 39.4548C422.979 39.4548 421.892 40.1374 421.076 41.3661C420.26 42.5948 419.988 44.0965 419.988 45.8713C419.988 47.5096 420.396 49.0113 421.076 50.1035C421.756 51.1957 422.979 51.7417 424.611 51.7417C426.379 51.6052 427.602 50.9226 428.146 49.6939Z" fill="#23376F"></path>
  <path d="M434.672 37.5435H437.255V39.8644C438.071 38.9087 438.751 38.2261 439.702 37.8165C440.518 37.407 441.47 37.2704 442.558 37.2704C444.869 37.2704 446.365 38.0896 447.044 39.5913C447.452 40.4104 447.724 41.6391 447.724 43.2774V53.5165H445.005V43.5504C445.005 42.5948 444.869 41.7757 444.597 41.2296C444.189 40.2739 443.238 39.7278 442.014 39.7278C441.334 39.7278 440.926 39.7278 440.518 39.8644C439.838 40.1374 439.159 40.547 438.615 41.2296C438.207 41.7757 437.935 42.3217 437.799 42.8678C437.663 43.4139 437.663 44.233 437.663 45.3252V53.653H434.944V37.5435H434.672Z" fill="#23376F"></path>
  <path d="M174.984 59.66C175.663 58.7043 176.751 58.2948 178.519 58.2948C178.655 58.2948 178.927 58.2948 179.063 58.2948C179.198 58.2948 179.47 58.2948 179.606 58.2948V60.7522C179.334 60.7522 179.063 60.7522 179.063 60.7522C178.927 60.7522 178.791 60.7522 178.655 60.7522C177.839 60.7522 177.295 61.0252 177.159 61.4348C177.023 61.8443 176.887 62.9365 176.887 64.7113H179.606V66.7591H176.887V80.4113H174.304V66.7591H172.128V64.7113H174.304V62.2539C174.44 61.0252 174.576 60.2061 174.984 59.66Z" fill="#23376F"></path>
  <path d="M193.067 66.0765C194.426 67.4417 195.106 69.4896 195.106 72.0835C195.106 74.6774 194.427 76.7252 193.203 78.3635C191.979 80.0017 190.076 80.8209 187.492 80.8209C185.317 80.8209 183.55 80.1383 182.326 78.6365C181.102 77.1348 180.422 75.2235 180.422 72.6296C180.422 69.8991 181.102 67.8513 182.462 66.213C183.821 64.5748 185.589 63.8922 187.9 63.8922C189.94 64.0287 191.571 64.7113 193.067 66.0765ZM191.435 76.5887C192.115 75.2235 192.387 73.7217 192.387 72.0835C192.387 70.5817 192.115 69.353 191.707 68.5339C191.027 67.0322 189.668 66.3496 187.9 66.3496C186.269 66.3496 185.181 67.0322 184.365 68.2609C183.685 69.4896 183.278 70.9913 183.278 72.7661C183.278 74.4043 183.685 75.9061 184.365 76.9983C185.045 78.0904 186.269 78.6365 187.9 78.6365C189.532 78.6365 190.756 77.9539 191.435 76.5887Z" fill="#23376F"></path>
  <path d="M197.961 64.5748H200.545V67.3052C200.817 66.7591 201.225 66.0765 202.04 65.3939C202.856 64.5748 203.808 64.3017 204.896 64.3017C204.896 64.3017 205.031 64.3017 205.167 64.3017C205.303 64.3017 205.439 64.3017 205.711 64.3017V67.1687C205.575 67.1687 205.439 67.1687 205.303 67.1687C205.167 67.1687 205.031 67.1687 204.896 67.1687C203.536 67.1687 202.584 67.5782 201.768 68.5339C201.089 69.353 200.681 70.4452 200.681 71.5374V80.6843H197.961V64.5748Z" fill="#23376F"></path>
  <path d="M215.773 60.0695H218.492V64.4382H221.075V66.6226H218.492V76.9983C218.492 77.5443 218.628 77.9539 219.036 78.0904C219.308 78.227 219.58 78.2269 220.123 78.2269C220.259 78.2269 220.395 78.2269 220.531 78.2269C220.667 78.2269 220.803 78.2269 221.075 78.2269V80.4113C220.803 80.5478 220.395 80.5478 220.123 80.5478C219.851 80.5478 219.444 80.5478 219.036 80.5478C217.812 80.5478 216.996 80.2748 216.452 79.5922C216.044 78.9096 215.773 78.0904 215.773 77.1348V66.6226H213.597V64.4382H215.773V60.0695V60.0695Z" fill="#23376F"></path>
  <path d="M223.114 58.5678H225.834V66.7591C226.513 65.94 227.057 65.3939 227.465 65.1209C228.281 64.5748 229.369 64.3017 230.728 64.3017C233.04 64.3017 234.535 65.1209 235.351 66.7591C235.759 67.5782 236.031 68.8069 236.031 70.4452V80.6843H233.311V70.7182C233.311 69.4895 233.175 68.6704 232.904 68.1243C232.36 67.3052 231.544 66.7591 230.184 66.7591C229.097 66.7591 228.145 67.1687 227.193 67.8513C226.241 68.6704 225.834 70.0356 225.834 72.22V80.6843H223.114V58.5678Z" fill="#23376F"></path>
  <path d="M249.219 64.9843C250.307 65.5304 251.123 66.213 251.667 67.0322C252.21 67.8513 252.482 68.8069 252.754 69.8991C252.89 70.5817 253.026 71.8104 253.026 73.4487H241.469C241.469 75.0869 241.877 76.3156 242.557 77.2713C243.237 78.2269 244.325 78.773 245.82 78.773C247.18 78.773 248.268 78.3635 249.083 77.4078C249.491 76.8617 249.899 76.3156 250.035 75.4965H252.618C252.482 76.0426 252.346 76.7252 251.939 77.4078C251.531 78.0904 251.123 78.773 250.579 79.1826C249.763 80.0017 248.811 80.5478 247.588 80.8208C246.908 80.9574 246.228 81.0939 245.412 81.0939C243.509 81.0939 241.741 80.4113 240.382 78.9095C239.022 77.4078 238.342 75.4965 238.342 72.9026C238.342 70.3087 239.022 68.2609 240.382 66.6226C241.741 64.9843 243.509 64.3017 245.82 64.3017C247.044 64.1652 248.132 64.4382 249.219 64.9843ZM250.171 71.1278C250.035 70.0356 249.763 69.08 249.355 68.3974C248.675 67.1687 247.452 66.4861 245.684 66.4861C244.461 66.4861 243.509 66.8956 242.693 67.8513C241.877 68.6704 241.469 69.8991 241.333 71.2643L250.171 71.1278Z" fill="#23376F"></path>
  <path d="M263.767 58.5678H273.149C275.732 58.5678 277.499 59.3869 278.587 60.8887C279.267 61.8443 279.539 62.8 279.539 64.0287C279.539 65.3939 279.131 66.4861 278.315 67.4417C277.907 67.8513 277.363 68.3974 276.548 68.6704C277.635 69.08 278.451 69.6261 278.995 70.1722C279.947 71.1278 280.491 72.493 280.491 74.1313C280.491 75.4965 280.083 76.8617 279.131 77.9539C277.771 79.5922 275.732 80.5478 272.877 80.5478H263.767V58.5678ZM272.061 67.8513C273.284 67.8513 274.236 67.7148 275.052 67.3052C276.14 66.7591 276.684 65.8035 276.684 64.3017C276.684 62.9365 276.14 61.9809 274.916 61.4348C274.236 61.1617 273.284 61.0252 271.925 61.0252H266.622V67.7148L272.061 67.8513ZM273.149 77.8174C274.916 77.8174 276.276 77.2713 277.091 76.1791C277.635 75.4965 277.771 74.6774 277.771 73.7217C277.771 72.0835 277.091 71.1278 275.596 70.4452C274.78 70.1722 273.828 70.0356 272.605 70.0356H266.758V77.8174H273.149Z" fill="#23376F"></path>
  <path d="M283.21 58.5678H285.929V80.4113H283.21V58.5678Z" fill="#23376F"></path>
  <path d="M289.328 58.5678H292.047V61.5713H289.328V58.5678ZM289.328 64.5748H292.047V80.4113H289.328V64.5748Z" fill="#23376F"></path>
  <path d="M295.583 64.5748H298.166V66.8956C298.982 65.94 299.661 65.2574 300.613 64.8478C301.429 64.4382 302.381 64.3017 303.468 64.3017C305.78 64.3017 307.275 65.1209 307.955 66.6226C308.363 67.4417 308.635 68.6704 308.635 70.3087V80.5478H305.916V70.5817C305.916 69.6261 305.78 68.8069 305.508 68.2609C305.1 67.3052 304.148 66.7591 302.925 66.7591C302.245 66.7591 301.837 66.7591 301.429 66.8956C300.749 67.1687 300.069 67.5782 299.525 68.2609C299.118 68.8069 298.846 69.353 298.71 69.8991C298.574 70.4452 298.574 71.2643 298.574 72.3565V80.6843H295.854V64.5748H295.583Z" fill="#23376F"></path>
  <path d="M320.464 64.9843C320.871 65.2574 321.415 65.8035 322.095 66.4861V58.4313H324.678V80.2748H322.231V78.0904C321.551 79.0461 320.871 79.7287 320.056 80.2748C319.24 80.6843 318.288 80.9574 317.2 80.9574C315.433 80.9574 313.937 80.2748 312.578 78.773C311.354 77.2713 310.674 75.36 310.674 72.7661C310.674 70.4452 311.218 68.3974 312.442 66.7591C313.665 65.1209 315.297 64.1652 317.472 64.1652C318.696 64.1652 319.648 64.4383 320.464 64.9843ZM314.617 76.8617C315.297 77.9539 316.521 78.6365 318.016 78.6365C319.24 78.6365 320.192 78.0904 321.007 76.9983C321.823 75.9061 322.231 74.4043 322.231 72.3565C322.231 70.3087 321.823 68.807 321.007 67.8513C320.192 66.8956 319.24 66.3496 318.016 66.3496C316.657 66.3496 315.569 66.8956 314.753 67.8513C313.937 68.9435 313.53 70.4452 313.53 72.3565C313.53 74.4043 313.801 75.7696 314.617 76.8617Z" fill="#23376F"></path>
  <path d="M30.1836 108.262H40.5167V110.037H32.0871V114.405H39.9729V116.043H32.0871V120.822H40.6527V122.46H30.1836V108.262Z" fill="#23376F"></path>
  <path d="M41.8764 112.221H44.1878L46.6351 115.907L49.0825 112.221H51.2579L47.5869 117.272L51.2579 122.597H48.9465L46.3632 118.637L43.9159 122.597H41.7405L45.4115 117.272L41.8764 112.221Z" fill="#23376F"></path>
  <path d="M52.4817 112.221H54.2492V113.586C54.6571 113.177 54.929 112.767 55.3369 112.494C55.8807 112.084 56.5605 111.948 57.3763 111.948C58.6 111.948 59.5517 112.357 60.3675 113.313C61.1833 114.269 61.5912 115.497 61.5912 117.136C61.5912 119.457 61.0473 120.958 59.8236 122.05C59.0079 122.597 58.1921 123.006 57.2404 123.006C56.4246 123.006 55.7448 122.87 55.2009 122.46C54.929 122.323 54.5211 121.914 54.1132 121.504V126.829H52.3457L52.4817 112.221ZM59.0079 120.412C59.5517 119.73 59.8236 118.774 59.8236 117.409C59.8236 116.59 59.6877 115.907 59.4158 115.224C59.0079 114.132 58.1921 113.45 56.9684 113.45C55.7448 113.45 54.929 113.996 54.5211 115.224C54.2492 115.907 54.1132 116.726 54.1132 117.682C54.1132 118.501 54.2492 119.183 54.5211 119.73C54.929 120.822 55.7448 121.368 56.9684 121.368C57.7842 121.368 58.464 121.095 59.0079 120.412Z" fill="#23376F"></path>
  <path d="M69.0688 116.317C69.4766 116.317 69.7486 116.043 69.8845 115.77C70.0205 115.634 70.0205 115.361 70.0205 115.088C70.0205 114.542 69.7486 113.996 69.3407 113.723C68.9328 113.45 68.253 113.313 67.5732 113.313C66.6214 113.313 66.0776 113.586 65.6697 113.996C65.3978 114.269 65.2618 114.678 65.2618 115.224H63.6303C63.6303 113.996 64.0381 113.04 64.8539 112.494C65.6697 111.948 66.6214 111.675 67.5732 111.675C68.7968 111.675 69.7486 111.948 70.5643 112.357C71.3801 112.767 71.652 113.586 71.652 114.542V120.549C71.652 120.685 71.652 120.822 71.788 120.958C71.924 121.095 72.0599 121.095 72.1959 121.095C72.3319 121.095 72.3319 121.095 72.4678 121.095C72.6038 121.095 72.6038 121.095 72.7397 121.095V122.323C72.4678 122.46 72.1959 122.46 72.0599 122.46C71.924 122.46 71.788 122.46 71.5161 122.46C70.9722 122.46 70.4284 122.187 70.1565 121.777C70.0205 121.504 69.8845 121.231 69.8845 120.822C69.4766 121.231 69.0688 121.641 68.3889 122.05C67.7091 122.46 67.0293 122.597 66.2135 122.597C65.2618 122.597 64.446 122.323 63.9022 121.777C63.3583 121.231 62.9504 120.412 62.9504 119.593C62.9504 118.637 63.2224 117.818 63.9022 117.409C64.446 116.863 65.2618 116.59 66.2135 116.453L69.0688 116.317ZM65.3978 120.958C65.8057 121.231 66.2135 121.368 66.7574 121.368C67.4372 121.368 67.9811 121.231 68.5249 120.958C69.4766 120.549 70.0205 119.73 70.0205 118.637V117.272C69.7486 117.409 69.4766 117.545 69.2047 117.545C68.9328 117.682 68.5249 117.682 68.253 117.682L67.1653 117.818C66.4855 117.955 66.0776 118.091 65.6697 118.228C65.1258 118.501 64.8539 119.047 64.8539 119.73C64.718 120.276 64.9899 120.685 65.3978 120.958Z" fill="#23376F"></path>
  <path d="M74.2354 112.221H75.8669V113.723C76.4108 113.177 76.8186 112.63 77.4985 112.357C78.0423 112.084 78.7221 111.948 79.4019 111.948C80.8975 111.948 81.8493 112.494 82.3931 113.45C82.665 113.996 82.801 114.815 82.801 115.907V122.597H81.0335V116.043C81.0335 115.361 80.8975 114.951 80.7616 114.542C80.4896 113.859 79.9458 113.586 79.13 113.586C78.7221 113.586 78.3142 113.586 78.0423 113.723C77.4985 113.859 77.0906 114.132 76.8186 114.542C76.5467 114.951 76.2748 115.224 76.2748 115.634C76.1388 116.043 76.1388 116.59 76.1388 117.136V122.597H74.3713V112.221H74.2354Z" fill="#23376F"></path>
  <path d="M90.8231 112.494C91.095 112.63 91.5029 113.04 91.9108 113.45V108.125H93.5423V122.46H91.9108V120.958C91.5029 121.641 90.959 122.05 90.4152 122.323C89.8713 122.597 89.1915 122.733 88.5117 122.733C87.424 122.733 86.3363 122.187 85.5205 121.231C84.7048 120.276 84.2969 118.91 84.2969 117.272C84.2969 115.77 84.7048 114.405 85.5205 113.313C86.3363 112.221 87.424 111.675 88.7836 111.675C89.7354 111.948 90.2792 112.084 90.8231 112.494ZM87.0161 120.276C87.424 121.095 88.2398 121.368 89.3275 121.368C90.1433 121.368 90.8231 120.958 91.3669 120.276C91.9108 119.593 92.1827 118.637 92.1827 117.272C92.1827 115.907 91.9108 114.951 91.3669 114.405C90.8231 113.723 90.1433 113.45 89.3275 113.45C88.5117 113.45 87.6959 113.723 87.1521 114.405C86.6082 115.088 86.3363 116.043 86.3363 117.409C86.3363 118.637 86.4723 119.457 87.0161 120.276Z" fill="#23376F"></path>
  <path d="M95.9893 108.262H97.7568V110.31H95.9893V108.262ZM95.9893 112.221H97.7568V122.597H95.9893V112.221Z" fill="#23376F"></path>
  <path d="M100.204 112.221H101.972V113.723C102.515 113.177 102.923 112.63 103.603 112.357C104.147 112.084 104.827 111.948 105.507 111.948C107.002 111.948 107.954 112.494 108.498 113.45C108.77 113.996 108.906 114.815 108.906 115.907V122.597H107.138V116.043C107.138 115.361 107.002 114.951 106.866 114.542C106.594 113.859 106.05 113.586 105.235 113.586C104.827 113.586 104.419 113.586 104.147 113.723C103.603 113.859 103.195 114.132 102.923 114.542C102.651 114.951 102.38 115.224 102.38 115.634C102.244 116.043 102.244 116.59 102.244 117.136V122.597H100.476V112.221C100.476 112.221 100.204 112.221 100.204 112.221Z" fill="#23376F"></path>
  <path d="M116.928 112.63C117.2 112.903 117.608 113.177 117.88 113.586V112.221H119.511V121.641C119.511 123.006 119.375 123.962 118.967 124.781C118.287 126.146 116.928 126.965 114.888 126.965C113.801 126.965 112.849 126.692 112.033 126.146C111.217 125.6 110.81 124.917 110.81 123.825H112.577C112.713 124.235 112.849 124.644 113.121 124.917C113.529 125.327 114.073 125.463 115.024 125.463C116.384 125.463 117.2 125.054 117.608 124.098C117.88 123.552 118.016 122.597 117.88 121.095C117.472 121.641 117.064 122.05 116.656 122.323C116.112 122.597 115.568 122.733 114.752 122.733C113.665 122.733 112.713 122.323 111.761 121.504C110.945 120.685 110.538 119.32 110.538 117.545C110.538 115.77 110.945 114.405 111.761 113.45C112.577 112.494 113.665 111.948 114.888 111.948C115.568 111.948 116.248 112.221 116.928 112.63ZM117.064 114.405C116.52 113.723 115.84 113.45 115.024 113.45C113.801 113.45 112.985 113.996 112.577 115.224C112.305 115.907 112.169 116.59 112.169 117.682C112.169 118.91 112.441 119.73 112.849 120.412C113.257 120.958 113.937 121.368 114.752 121.368C115.976 121.368 116.928 120.822 117.336 119.73C117.608 119.047 117.744 118.364 117.744 117.545C117.88 116.043 117.608 115.088 117.064 114.405Z" fill="#23376F"></path>
  <path d="M127.125 112.221H128.892V113.586C129.3 113.177 129.572 112.767 129.98 112.494C130.524 112.084 131.204 111.948 132.019 111.948C133.243 111.948 134.195 112.357 135.011 113.313C135.826 114.269 136.234 115.497 136.234 117.136C136.234 119.457 135.69 120.958 134.467 122.05C133.787 122.597 132.971 122.87 131.883 122.87C131.068 122.87 130.388 122.733 129.844 122.323C129.572 122.187 129.164 121.777 128.756 121.368V126.692H126.989V112.221H127.125ZM133.787 120.412C134.331 119.73 134.603 118.774 134.603 117.409C134.603 116.59 134.467 115.907 134.195 115.224C133.787 114.132 132.971 113.45 131.747 113.45C130.524 113.45 129.708 113.996 129.3 115.224C129.028 115.907 128.892 116.726 128.892 117.682C128.892 118.501 129.028 119.183 129.3 119.73C129.708 120.822 130.524 121.368 131.747 121.368C132.563 121.368 133.243 121.095 133.787 120.412Z" fill="#23376F"></path>
  <path d="M145.752 113.177C146.703 114.132 147.111 115.361 147.111 117.136C147.111 118.774 146.703 120.139 145.888 121.231C145.072 122.323 143.848 122.87 142.081 122.87C140.721 122.87 139.497 122.323 138.682 121.368C137.866 120.412 137.458 119.047 137.458 117.409C137.458 115.634 137.866 114.269 138.818 113.177C139.633 112.084 140.857 111.675 142.353 111.675C143.712 111.811 144.8 112.357 145.752 113.177ZM144.664 120.139C145.072 119.32 145.344 118.228 145.344 117.272C145.344 116.317 145.208 115.497 144.936 114.951C144.392 113.996 143.576 113.45 142.353 113.45C141.265 113.45 140.585 113.859 140.041 114.678C139.497 115.497 139.361 116.453 139.361 117.682C139.361 118.774 139.633 119.73 140.041 120.412C140.585 121.095 141.265 121.504 142.353 121.504C143.44 121.368 144.256 120.958 144.664 120.139Z" fill="#23376F"></path>
  <path d="M150.103 119.32C150.103 119.866 150.239 120.412 150.511 120.685C150.919 121.231 151.734 121.504 152.822 121.504C153.502 121.504 154.046 121.368 154.59 121.095C155.133 120.822 155.405 120.412 155.405 119.73C155.405 119.32 155.269 118.91 154.861 118.774C154.59 118.637 154.046 118.501 153.366 118.228L152.006 117.818C151.055 117.545 150.375 117.409 149.967 117.136C149.151 116.726 148.879 116.043 148.879 115.224C148.879 114.269 149.287 113.45 149.967 112.767C150.647 112.221 151.598 111.811 152.822 111.811C154.454 111.811 155.541 112.221 156.221 113.177C156.629 113.723 156.901 114.405 156.901 115.088H155.269C155.269 114.678 155.133 114.269 154.861 113.996C154.454 113.586 153.774 113.313 152.686 113.313C152.006 113.313 151.462 113.45 151.19 113.723C150.783 113.996 150.647 114.269 150.647 114.678C150.647 115.088 150.919 115.497 151.326 115.77C151.598 115.907 152.006 116.043 152.55 116.18L153.774 116.453C154.997 116.726 155.949 116.999 156.357 117.272C157.037 117.682 157.445 118.364 157.445 119.32C157.445 120.276 157.037 121.095 156.357 121.777C155.677 122.46 154.59 122.733 153.094 122.733C151.462 122.733 150.375 122.323 149.831 121.641C149.151 120.958 148.879 120.003 148.743 119.047H150.103V119.32Z" fill="#23376F"></path>
  <path d="M159.756 119.32C159.756 119.866 159.892 120.412 160.164 120.685C160.572 121.231 161.388 121.504 162.475 121.504C163.155 121.504 163.699 121.368 164.243 121.095C164.787 120.822 165.059 120.412 165.059 119.73C165.059 119.32 164.923 118.91 164.515 118.774C164.243 118.637 163.699 118.501 163.019 118.228L161.66 117.818C160.708 117.545 160.028 117.409 159.62 117.136C158.804 116.726 158.532 116.043 158.532 115.224C158.532 114.269 158.94 113.45 159.62 112.767C160.3 112.221 161.252 111.811 162.475 111.811C164.107 111.811 165.195 112.221 165.874 113.177C166.282 113.723 166.554 114.405 166.554 115.088H164.923C164.923 114.678 164.787 114.269 164.515 113.996C164.107 113.586 163.427 113.313 162.339 113.313C161.66 113.313 161.116 113.45 160.844 113.723C160.436 113.996 160.3 114.269 160.3 114.678C160.3 115.088 160.572 115.497 160.98 115.77C161.252 115.907 161.66 116.043 162.203 116.18L163.427 116.453C164.651 116.726 165.602 116.999 166.01 117.272C166.69 117.682 167.098 118.364 167.098 119.32C167.098 120.276 166.69 121.095 166.01 121.777C165.331 122.46 164.243 122.733 162.747 122.733C161.116 122.733 160.028 122.323 159.484 121.641C158.804 120.958 158.532 120.003 158.396 119.047H159.756V119.32Z" fill="#23376F"></path>
  <path d="M168.458 108.262H170.225V110.31H168.458V108.262ZM168.458 112.221H170.225V122.597H168.458V112.221Z" fill="#23376F"></path>
  <path d="M172.537 108.262H174.304V113.45C174.712 112.903 175.12 112.63 175.664 112.357C176.208 112.084 176.751 111.948 177.431 111.948C178.791 111.948 179.743 112.357 180.558 113.313C181.374 114.269 181.782 115.497 181.782 117.272C181.782 118.91 181.374 120.276 180.558 121.368C179.743 122.46 178.655 123.006 177.295 123.006C176.48 123.006 175.8 122.87 175.256 122.46C174.984 122.187 174.576 121.914 174.168 121.368V122.733H172.537C172.537 122.733 172.537 108.262 172.537 108.262ZM179.335 120.276C179.743 119.457 180.015 118.501 180.015 117.272C180.015 116.18 179.743 115.224 179.335 114.542C178.927 113.859 178.111 113.45 177.295 113.45C176.48 113.45 175.8 113.723 175.256 114.269C174.712 114.815 174.304 115.77 174.304 117.136C174.304 118.091 174.44 118.91 174.712 119.457C175.12 120.549 176.072 121.231 177.295 121.231C178.111 121.368 178.791 120.958 179.335 120.276Z" fill="#23376F"></path>
  <path d="M183.549 108.262H185.317V110.31H183.549V108.262ZM183.549 112.221H185.317V122.597H183.549V112.221Z" fill="#23376F"></path>
  <path d="M187.9 108.262H189.667V122.597H187.9V108.262Z" fill="#23376F"></path>
  <path d="M191.979 108.262H193.747V110.31H191.979V108.262ZM191.979 112.221H193.747V122.597H191.979V112.221Z" fill="#23376F"></path>
  <path d="M196.602 109.217H198.369V112.084H200.001V113.45H198.369V120.276C198.369 120.685 198.505 120.822 198.777 120.958C198.913 121.095 199.185 121.095 199.457 121.095C199.593 121.095 199.593 121.095 199.729 121.095C199.865 121.095 200.001 121.095 200.001 121.095V122.46C199.865 122.46 199.593 122.597 199.321 122.597C199.049 122.597 198.913 122.597 198.641 122.597C197.825 122.597 197.282 122.323 197.01 121.914C196.738 121.504 196.602 120.958 196.602 120.276V113.45H195.242V112.084H196.602V109.217Z" fill="#23376F"></path>
  <path d="M201.632 108.262H203.4V110.31H201.632V108.262ZM201.632 112.221H203.4V122.597H201.632V112.221Z" fill="#23376F"></path>
  <path d="M212.238 112.494C212.917 112.903 213.461 113.313 213.869 113.859C214.277 114.405 214.413 114.951 214.549 115.634C214.685 116.18 214.685 116.863 214.685 117.955H207.071C207.071 119.047 207.343 119.866 207.751 120.549C208.159 121.231 208.974 121.504 209.926 121.504C210.878 121.504 211.558 121.231 212.102 120.549C212.374 120.139 212.645 119.73 212.781 119.32H214.549C214.549 119.73 214.413 120.139 214.141 120.549C213.869 120.958 213.597 121.368 213.325 121.641C212.781 122.187 212.102 122.46 211.422 122.733C211.014 122.87 210.47 122.87 210.062 122.87C208.839 122.87 207.751 122.46 206.799 121.504C205.847 120.549 205.439 119.183 205.439 117.545C205.439 115.907 205.847 114.542 206.799 113.45C207.751 112.357 208.839 111.948 210.334 111.948C210.878 111.948 211.558 112.084 212.238 112.494ZM212.917 116.59C212.781 115.77 212.645 115.224 212.374 114.815C211.966 113.996 211.15 113.586 210.062 113.586C209.246 113.586 208.567 113.859 208.023 114.405C207.479 114.951 207.207 115.77 207.207 116.59C207.207 116.59 212.917 116.59 212.917 116.59Z" fill="#23376F"></path>
  <path d="M217.676 119.32C217.676 119.866 217.812 120.412 218.084 120.685C218.492 121.231 219.307 121.504 220.395 121.504C221.075 121.504 221.619 121.368 222.163 121.095C222.706 120.822 222.978 120.412 222.978 119.73C222.978 119.32 222.842 118.91 222.434 118.774C222.163 118.637 221.619 118.501 220.939 118.228L219.579 117.818C218.627 117.545 217.948 117.409 217.54 117.136C216.724 116.726 216.452 116.043 216.452 115.224C216.452 114.269 216.86 113.45 217.54 112.767C218.22 112.221 219.171 111.811 220.395 111.811C222.027 111.811 223.114 112.221 223.794 113.177C224.202 113.723 224.474 114.405 224.474 115.088H222.842C222.842 114.678 222.706 114.269 222.434 113.996C222.027 113.586 221.347 113.313 220.259 113.313C219.579 113.313 219.035 113.45 218.763 113.723C218.356 113.996 218.22 114.269 218.22 114.678C218.22 115.088 218.492 115.497 218.899 115.77C219.171 115.907 219.579 116.043 220.123 116.18L221.347 116.453C222.57 116.726 223.522 116.999 223.93 117.272C224.61 117.682 224.882 118.364 224.882 119.32C224.882 120.276 224.474 121.095 223.794 121.777C223.114 122.46 222.027 122.733 220.531 122.733C218.899 122.733 217.812 122.323 217.268 121.641C216.724 120.958 216.316 120.003 216.18 119.047H217.676V119.32V119.32Z" fill="#23376F"></path>
  <path d="M232.632 108.944C233.04 108.398 233.855 107.989 234.943 107.989C235.079 107.989 235.215 107.989 235.215 107.989C235.351 107.989 235.487 107.989 235.623 107.989V109.627C235.487 109.627 235.351 109.627 235.215 109.627C235.079 109.627 235.079 109.627 234.943 109.627C234.399 109.627 234.127 109.763 233.991 110.037C233.855 110.31 233.855 110.992 233.855 112.084H235.623V113.45H233.855V122.46H232.088V113.45H230.592V112.084H232.088V110.583C232.224 109.9 232.36 109.354 232.632 108.944Z" fill="#23376F"></path>
  <path d="M244.596 113.177C245.548 114.132 245.956 115.361 245.956 117.136C245.956 118.774 245.548 120.139 244.732 121.231C243.917 122.323 242.693 122.87 240.925 122.87C239.566 122.87 238.342 122.323 237.526 121.368C236.711 120.412 236.303 119.047 236.303 117.409C236.303 115.634 236.711 114.269 237.662 113.177C238.478 112.084 239.702 111.675 241.197 111.675C242.557 111.811 243.645 112.357 244.596 113.177ZM243.509 120.139C243.917 119.32 244.189 118.228 244.189 117.272C244.189 116.317 244.053 115.497 243.781 114.951C243.237 113.996 242.421 113.45 241.197 113.45C240.11 113.45 239.43 113.859 238.886 114.678C238.342 115.497 238.206 116.453 238.206 117.682C238.206 118.774 238.478 119.73 238.886 120.412C239.43 121.095 240.11 121.504 241.197 121.504C242.285 121.368 243.101 120.958 243.509 120.139Z" fill="#23376F"></path>
  <path d="M247.996 112.221H249.627V113.996C249.763 113.586 250.035 113.177 250.579 112.767C251.123 112.221 251.667 111.948 252.482 111.948H252.618C252.754 111.948 252.89 111.948 253.026 111.948V113.859C252.89 113.859 252.89 113.859 252.754 113.859C252.618 113.859 252.618 113.859 252.482 113.859C251.667 113.859 250.987 114.132 250.443 114.678C250.035 115.224 249.763 115.907 249.763 116.59V122.597H247.996V112.221Z" fill="#23376F"></path>
  <path d="M259.552 112.221H261.32V113.586C261.728 113.177 262 112.767 262.408 112.494C262.952 112.084 263.631 111.948 264.447 111.948C265.671 111.948 266.622 112.357 267.438 113.313C268.254 114.269 268.662 115.497 268.662 117.136C268.662 119.457 268.118 120.958 266.894 122.05C266.079 122.597 265.263 123.006 264.311 123.006C263.495 123.006 262.816 122.87 262.272 122.46C262 122.323 261.592 121.914 261.184 121.504V126.829H259.417V112.221H259.552ZM266.079 120.412C266.622 119.73 266.894 118.774 266.894 117.409C266.894 116.59 266.758 115.907 266.487 115.224C266.079 114.132 265.263 113.45 264.039 113.45C262.816 113.45 262 113.996 261.592 115.224C261.32 115.907 261.184 116.726 261.184 117.682C261.184 118.501 261.32 119.183 261.592 119.73C262 120.822 262.816 121.368 264.039 121.368C264.855 121.368 265.535 121.095 266.079 120.412Z" fill="#23376F"></path>
  <path d="M276.956 112.494C277.636 112.903 278.179 113.313 278.587 113.859C278.995 114.405 279.131 114.951 279.267 115.634C279.403 116.18 279.403 116.863 279.403 117.955H271.789C271.789 119.047 272.061 119.866 272.469 120.549C272.877 121.231 273.693 121.504 274.644 121.504C275.596 121.504 276.276 121.231 276.82 120.549C277.092 120.139 277.364 119.73 277.5 119.32H279.267C279.267 119.73 279.131 120.139 278.859 120.549C278.587 120.958 278.315 121.368 278.044 121.641C277.5 122.187 276.82 122.46 276.14 122.733C275.732 122.87 275.188 122.87 274.78 122.87C273.557 122.87 272.469 122.46 271.517 121.504C270.566 120.549 270.158 119.183 270.158 117.545C270.158 115.907 270.566 114.542 271.517 113.45C272.469 112.357 273.557 111.948 275.052 111.948C275.596 111.948 276.276 112.084 276.956 112.494ZM277.636 116.59C277.5 115.77 277.364 115.224 277.092 114.815C276.684 113.996 275.868 113.586 274.78 113.586C273.965 113.586 273.285 113.859 272.741 114.405C272.197 114.951 271.925 115.77 271.925 116.59C271.925 116.59 277.636 116.59 277.636 116.59Z" fill="#23376F"></path>
  <path d="M288.92 113.177C289.872 114.132 290.28 115.361 290.28 117.136C290.28 118.774 289.872 120.139 289.056 121.231C288.24 122.323 287.017 122.87 285.249 122.87C283.89 122.87 282.666 122.323 281.85 121.368C281.034 120.412 280.626 119.047 280.626 117.409C280.626 115.634 281.034 114.269 281.986 113.177C282.802 112.084 284.026 111.675 285.521 111.675C286.881 111.811 287.968 112.357 288.92 113.177ZM287.833 120.139C288.24 119.32 288.512 118.228 288.512 117.272C288.512 116.317 288.376 115.497 288.104 114.951C287.561 113.996 286.745 113.45 285.521 113.45C284.433 113.45 283.754 113.859 283.21 114.678C282.666 115.497 282.53 116.453 282.53 117.682C282.53 118.774 282.802 119.73 283.21 120.412C283.754 121.095 284.433 121.504 285.521 121.504C286.609 121.368 287.425 120.958 287.833 120.139Z" fill="#23376F"></path>
  <path d="M292.183 112.221H293.951V113.586C294.359 113.177 294.631 112.767 295.039 112.494C295.582 112.084 296.262 111.948 297.078 111.948C298.302 111.948 299.253 112.357 300.069 113.313C300.885 114.269 301.293 115.497 301.293 117.136C301.293 119.457 300.749 120.958 299.525 122.05C298.71 122.597 297.894 123.006 296.942 123.006C296.126 123.006 295.446 122.87 294.903 122.46C294.631 122.323 294.223 121.914 293.815 121.504V126.829H292.047V112.221H292.183ZM298.71 120.412C299.253 119.73 299.525 118.774 299.525 117.409C299.525 116.59 299.389 115.907 299.117 115.224C298.71 114.132 297.894 113.45 296.67 113.45C295.446 113.45 294.631 113.996 294.223 115.224C293.951 115.907 293.815 116.726 293.815 117.682C293.815 118.501 293.951 119.183 294.223 119.73C294.631 120.822 295.446 121.368 296.67 121.368C297.486 121.368 298.166 121.095 298.71 120.412Z" fill="#23376F"></path>
  <path d="M303.196 108.262H304.964V122.597H303.196V108.262Z" fill="#23376F"></path>
  <path d="M313.802 112.494C314.481 112.903 315.025 113.313 315.433 113.859C315.841 114.405 315.977 114.951 316.113 115.634C316.249 116.18 316.249 116.863 316.249 117.955H308.635C308.635 119.047 308.907 119.866 309.315 120.549C309.723 121.231 310.538 121.504 311.49 121.504C312.442 121.504 313.122 121.231 313.666 120.549C313.937 120.139 314.209 119.73 314.345 119.32H316.113C316.113 119.73 315.977 120.139 315.705 120.549C315.433 120.958 315.161 121.368 314.889 121.641C314.345 122.187 313.666 122.46 312.986 122.733C312.578 122.87 312.034 122.87 311.626 122.87C310.402 122.87 309.315 122.46 308.363 121.504C307.411 120.549 307.003 119.183 307.003 117.545C307.003 115.907 307.411 114.542 308.363 113.45C309.315 112.357 310.402 111.948 311.898 111.948C312.442 111.948 313.122 112.084 313.802 112.494ZM314.481 116.59C314.345 115.77 314.209 115.224 313.937 114.815C313.53 113.996 312.714 113.586 311.626 113.586C310.81 113.586 310.131 113.859 309.587 114.405C309.043 114.951 308.771 115.77 308.771 116.59C308.771 116.59 314.481 116.59 314.481 116.59Z" fill="#23376F"></path>
  <path d="M324.407 112.221L326.446 120.412L328.486 112.221H330.389L332.428 120.412L334.604 112.221H336.371L333.38 122.597H331.613L329.437 114.542L327.398 122.597H325.63L322.639 112.221H324.407Z" fill="#23376F"></path>
  <path d="M337.731 108.262H339.498V110.31H337.731V108.262ZM337.731 112.221H339.498V122.597H337.731V112.221Z" fill="#23376F"></path>
  <path d="M342.354 109.217H344.121V112.084H345.753V113.45H344.121V120.276C344.121 120.685 344.257 120.822 344.529 120.958C344.665 121.095 344.937 121.095 345.209 121.095C345.345 121.095 345.345 121.095 345.481 121.095C345.617 121.095 345.753 121.095 345.889 121.095V122.46C345.753 122.46 345.481 122.597 345.209 122.597C344.937 122.597 344.801 122.597 344.529 122.597C343.713 122.597 343.169 122.323 342.897 121.914C342.625 121.504 342.49 120.958 342.49 120.276V113.45H341.13V112.084H342.49V109.217H342.354Z" fill="#23376F"></path>
  <path d="M347.248 108.262H349.016V113.586C349.423 113.04 349.831 112.63 350.103 112.494C350.647 112.084 351.327 111.948 352.143 111.948C353.638 111.948 354.726 112.494 355.27 113.586C355.542 114.132 355.678 114.951 355.678 116.043V122.733H353.91V116.043C353.91 115.224 353.774 114.678 353.638 114.405C353.366 113.859 352.687 113.586 351.871 113.586C351.191 113.586 350.511 113.859 349.967 114.269C349.423 114.815 349.016 115.77 349.016 117.136V122.597H347.248V108.262Z" fill="#23376F"></path>
  <path d="M364.38 112.221L367.099 120.685L369.954 112.221H371.857L367.915 122.597H366.011L362.204 112.221H364.38Z" fill="#23376F"></path>
  <path d="M373.217 108.262H374.985V110.31H373.217V108.262ZM373.217 112.221H374.985V122.597H373.217V112.221Z" fill="#23376F"></path>
  <path d="M378.52 119.32C378.52 119.866 378.656 120.412 378.928 120.685C379.336 121.231 380.152 121.504 381.239 121.504C381.919 121.504 382.463 121.368 383.007 121.095C383.551 120.822 383.822 120.412 383.822 119.73C383.822 119.32 383.687 118.91 383.279 118.774C383.007 118.637 382.463 118.501 381.783 118.228L380.423 117.818C379.472 117.545 378.792 117.409 378.384 117.136C377.568 116.726 377.296 116.043 377.296 115.224C377.296 114.269 377.704 113.45 378.384 112.767C379.064 112.221 380.016 111.811 381.239 111.811C382.871 111.811 383.958 112.221 384.638 113.177C385.046 113.723 385.318 114.405 385.318 115.088H383.415C383.415 114.678 383.279 114.269 383.007 113.996C382.599 113.586 381.919 113.313 380.831 113.313C380.152 113.313 379.608 113.45 379.336 113.723C378.928 113.996 378.792 114.269 378.792 114.678C378.792 115.088 379.064 115.497 379.472 115.77C379.744 115.907 380.152 116.043 380.695 116.18L381.919 116.453C383.143 116.726 384.094 116.999 384.502 117.272C385.182 117.682 385.454 118.364 385.454 119.32C385.454 120.276 385.046 121.095 384.366 121.777C383.687 122.46 382.599 122.733 381.103 122.733C379.472 122.733 378.384 122.323 377.84 121.641C377.16 120.958 376.888 120.003 376.752 119.047H378.52V119.32Z" fill="#23376F"></path>
  <path d="M387.221 108.262H388.989V110.31H387.221V108.262ZM387.221 112.221H388.989V122.597H387.221V112.221Z" fill="#23376F"></path>
  <path d="M398.914 113.177C399.866 114.132 400.273 115.361 400.273 117.136C400.273 118.774 399.866 120.139 399.05 121.231C398.234 122.323 397.01 122.87 395.243 122.87C393.883 122.87 392.66 122.323 391.844 121.368C391.028 120.412 390.62 119.047 390.62 117.409C390.62 115.634 391.028 114.269 391.98 113.177C392.796 112.084 394.019 111.675 395.515 111.675C396.874 111.811 397.962 112.357 398.914 113.177ZM397.826 120.139C398.234 119.32 398.506 118.228 398.506 117.272C398.506 116.317 398.37 115.497 398.098 114.951C397.554 113.996 396.738 113.45 395.515 113.45C394.427 113.45 393.747 113.859 393.203 114.678C392.66 115.497 392.524 116.453 392.524 117.682C392.524 118.774 392.796 119.73 393.203 120.412C393.747 121.095 394.427 121.504 395.515 121.504C396.602 121.368 397.418 120.958 397.826 120.139Z" fill="#23376F"></path>
  <path d="M402.313 112.221H403.945V113.723C404.488 113.177 404.896 112.63 405.44 112.357C405.984 112.084 406.664 111.948 407.344 111.948C408.839 111.948 409.791 112.494 410.335 113.45C410.607 113.996 410.743 114.815 410.743 115.907V122.597H408.975V116.043C408.975 115.361 408.839 114.951 408.703 114.542C408.431 113.859 407.887 113.586 407.072 113.586C406.664 113.586 406.256 113.586 406.12 113.723C405.576 113.859 405.168 114.132 404.896 114.542C404.624 114.951 404.352 115.224 404.352 115.634C404.216 116.043 404.216 116.59 404.216 117.136V122.597H402.449V112.221C402.449 112.221 402.313 112.221 402.313 112.221Z" fill="#23376F"></path>
  <path d="M418.493 108.262H420.26V122.597H418.493V108.262Z" fill="#23376F"></path>
  <path d="M430.185 113.177C431.137 114.132 431.545 115.361 431.545 117.136C431.545 118.774 431.137 120.139 430.321 121.231C429.506 122.323 428.282 122.87 426.514 122.87C425.155 122.87 423.931 122.323 423.115 121.368C422.3 120.412 421.892 119.047 421.892 117.409C421.892 115.634 422.299 114.269 423.251 113.177C424.067 112.084 425.291 111.675 426.786 111.675C428.146 111.811 429.234 112.357 430.185 113.177ZM429.098 120.139C429.506 119.32 429.777 118.228 429.777 117.272C429.777 116.317 429.641 115.497 429.37 114.951C428.826 113.996 428.01 113.45 426.786 113.45C425.699 113.45 425.019 113.859 424.475 114.678C423.931 115.497 423.795 116.453 423.795 117.682C423.795 118.774 424.067 119.73 424.475 120.412C425.019 121.095 425.699 121.504 426.786 121.504C427.874 121.368 428.69 120.958 429.098 120.139Z" fill="#23376F"></path>
  <path d="M434.536 119.32C434.536 119.866 434.672 120.412 434.944 120.685C435.352 121.231 436.168 121.504 437.255 121.504C437.935 121.504 438.479 121.368 439.023 121.095C439.567 120.822 439.839 120.412 439.839 119.73C439.839 119.32 439.703 118.91 439.295 118.774C439.023 118.637 438.479 118.501 437.799 118.228L436.44 117.818C435.488 117.545 434.808 117.409 434.4 117.136C433.584 116.726 433.312 116.043 433.312 115.224C433.312 114.269 433.72 113.45 434.4 112.767C435.08 112.221 436.032 111.811 437.255 111.811C438.887 111.811 439.975 112.221 440.654 113.177C441.062 113.723 441.334 114.405 441.334 115.088H439.703C439.703 114.678 439.567 114.269 439.295 113.996C438.887 113.586 438.207 113.313 437.119 113.313C436.44 113.313 435.896 113.45 435.624 113.723C435.216 113.996 435.08 114.269 435.08 114.678C435.08 115.088 435.352 115.497 435.76 115.77C436.032 115.907 436.44 116.043 436.983 116.18L438.207 116.453C439.431 116.726 440.383 116.999 440.79 117.272C441.47 117.682 441.742 118.364 441.742 119.32C441.742 120.276 441.334 121.095 440.654 121.777C439.975 122.46 438.887 122.733 437.391 122.733C435.76 122.733 434.672 122.323 434.128 121.641C433.448 120.958 433.176 120.003 433.041 119.047H434.536V119.32Z" fill="#23376F"></path>
  <path d="M444.326 119.32C444.326 119.866 444.462 120.412 444.734 120.685C445.141 121.231 445.957 121.504 447.045 121.504C447.725 121.504 448.269 121.368 448.812 121.095C449.356 120.822 449.628 120.412 449.628 119.73C449.628 119.32 449.492 118.91 449.084 118.774C448.812 118.637 448.269 118.501 447.589 118.228L446.229 117.818C445.277 117.545 444.598 117.409 444.19 117.136C443.374 116.726 443.102 116.043 443.102 115.224C443.102 114.269 443.51 113.45 444.19 112.767C444.87 112.221 445.821 111.811 447.045 111.811C448.676 111.811 449.764 112.221 450.444 113.177C450.852 113.723 451.124 114.405 451.124 115.088H449.492C449.492 114.678 449.356 114.269 449.084 113.996C448.676 113.586 447.997 113.313 446.909 113.313C446.229 113.313 445.685 113.45 445.413 113.723C445.005 113.996 444.87 114.269 444.87 114.678C444.87 115.088 445.141 115.497 445.549 115.77C445.821 115.907 446.229 116.043 446.773 116.18L447.997 116.453C449.22 116.726 450.172 116.999 450.58 117.272C451.26 117.682 451.532 118.364 451.532 119.32C451.532 120.276 451.124 121.095 450.444 121.777C449.764 122.46 448.676 122.733 447.181 122.733C445.549 122.733 444.462 122.323 443.918 121.641C443.238 120.958 442.966 120.003 442.83 119.047H444.326V119.32Z" fill="#23376F"></path>
  <path d="M451.804 94.7461H30.0479V98.2957H451.804V94.7461Z" fill="#FFEA00"></path>
  <path d="M456.29 36.8609C455.474 37.68 454.523 38.0896 453.299 38.0896C452.211 38.0896 451.123 37.68 450.308 36.8609C449.492 36.0417 449.084 35.0861 449.084 33.8574C449.084 32.7652 449.492 31.673 450.308 30.8539C451.123 30.0348 452.075 29.6252 453.299 29.6252C454.523 29.6252 455.474 30.0348 456.29 30.8539C457.106 31.673 457.514 32.6287 457.514 33.8574C457.514 35.0861 457.106 36.0417 456.29 36.8609ZM450.716 31.2635C450.036 31.9461 449.628 32.7652 449.628 33.8574C449.628 34.813 450.036 35.7687 450.716 36.4513C451.395 37.1339 452.211 37.5435 453.299 37.5435C454.251 37.5435 455.202 37.1339 455.882 36.4513C456.562 35.7687 456.97 34.9496 456.97 33.8574C456.97 32.9017 456.562 31.9461 455.882 31.2635C455.202 30.5809 454.387 30.1713 453.299 30.1713C452.347 30.3078 451.395 30.5809 450.716 31.2635ZM453.163 31.5365C453.707 31.5365 454.115 31.5365 454.387 31.673C454.794 31.8096 455.066 32.2191 455.066 32.9017C455.066 33.3113 454.93 33.5843 454.658 33.8574C454.523 33.9939 454.251 33.9939 453.979 34.1304C454.387 34.1304 454.658 34.4035 454.794 34.54C454.93 34.813 455.066 35.0861 455.066 35.2226V35.4956C455.066 35.6322 455.066 35.7687 455.066 35.9052C455.066 36.0417 455.066 36.0417 455.066 36.1783H454.251V36.0417V35.4956C454.251 34.9496 454.115 34.6765 453.843 34.4035C453.707 34.267 453.435 34.267 452.891 34.267H452.211V36.0417H451.395V31.4H453.163V31.5365ZM454.115 32.2191C453.979 32.0826 453.571 32.0826 453.163 32.0826H452.483V33.7209H453.163C453.571 33.7209 453.707 33.7209 453.979 33.5843C454.251 33.4478 454.387 33.1748 454.387 32.9017C454.387 32.6287 454.251 32.3556 454.115 32.2191Z" fill="#1E376C"></path>
</svg>
    )}