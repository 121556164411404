import React from "react"
import { Link } from "gatsby"
import { issuesForSite, computeStatistics, getSite, allIssues, getTestDate } from "../data/msu_data"
import IssueChart from "../components/issueChart"
import AfbLogo from "../components/afb_logo"
import _ from "lodash"

const bigNumber = {
  fontSize: "3em"
}

class basicTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      criticalOnly: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    console.log(this.state.criticalOnly)
  }

  filterData(criticalOnly, issues) {
    var filteredIssues = issues
    if (criticalOnly) {
      filteredIssues = _.filter(issues, (o) => { return o.["Impact"] === "Blocker" || o.["Impact"] === "High" })
    }

    var nCategory = _.countBy(filteredIssues, (o) => {
      var candidate = _.get(o, "Category", "none") !== "Needs Discussion" ? _.get(o, "Category", "none") : "Other"
      return candidate
    })

    return Object.entries(nCategory).map(function ([k, v]) { return { name: k, y: v } })
  }

  render() {
    const { pageContext } = this.props
    const { id } = pageContext
    var severity = _.countBy(issuesForSite(id), (o) => {
      return _.get(o, "fields.impact.value", "none")
    })
    var siteIssues = issuesForSite(id)
    var site = getSite(id)
    var testDate = getTestDate(site["company"])
    var siteStats = computeStatistics(issuesForSite(id))
    var allSiteStats = computeStatistics(allIssues())
    var blockers = _.filter(siteIssues, (i) => { return i["Impact"] === "Blocker" })
    var myHighBlockers = (siteStats["severity"]["Blocker"] || 0) + (siteStats["severity"]["High"] || 0)
    var averageHighBlockers = ((allSiteStats["severity"]["Blocker"] || 0) + (allSiteStats["severity"]["High"] || 0)) / 30.0
    return (
      <article className="container">
        <header className="row"><div className="col-12 col-xl-4"><AfbLogo height="136" width="400" /></div><div className="col-12 col-xl-8"><h1> {site["company"]}<br />
          <small className="text-muted">Accessibility Report Card - Job Application Process</small></h1>
          <p>Date Tested: {testDate} | Report Prepared: 9/13/2021</p></div>
        </header>
        <section className="row">
          <div className="col-12 col-xl-4">
            <h2>Summary of Methods</h2>
            <p>Researchers collected feedback from three expert usability testers, each using different screen reader combinations, to complete your online job application process. They reported technical accessibility and usability issues and completed a survey questionnaire summarizing their experiences. Two testers represented a moderately-skilled persona with screen reader skills appropriate for a professional work environment while the third tester represented an advanced-skilled persona, who has more technical skills and can attempt to work around encountered problems.
            </p><p>For each found issue, testers described the impact on the user and the category of the error related to the WCAG 2.1 success criteria. A job application site was considered to fail if usability testers found one or more critical impact accessibility or usability issues during the testing. After completing each online job application, the tester responded to a series of questions to assess challenges, ease of use, and whether the site worked as expected. Questions included open-ended responses and rating scales.
            </p>
            <div>
              We encountered <span style={{ fontSize: "3em" }}>{siteStats["severity"]["Blocker"] || 0}</span> User Blockers
            </div>
            <h2>Severity Ratings</h2>
            <dl>
              <dt>Blocker</dt>
              <dd>Important use case issue that cannot be worked around, preventing completion.</dd>
              <dt>High</dt>
              <dd>Important use case issue that can be bypassed with some or major difficulty.</dd>
              <dt>Moderate</dt>
              <dd>Issue that is not part of an important path or can be easily worked around.</dd>
              <dt>Low</dt>
              <dd>Issue that is both not part of an important path and can be easily worked around.</dd>
            </dl>
          </div>
          <div className="col-12 col-xl-8">
            {/* Issues found */}
            <h2>Issues Found</h2>
            <hr />
            <div className="row g-4">
              <div className="col">
                <div className="card text-center text-white bg-dark">Blockers <span style={bigNumber}>{siteStats["severity"]["Blocker"] || 0}</span></div></div>
              <div className="col">
                <div className="card text-center text-white bg-danger">High <span style={bigNumber}>{siteStats["severity"]["High"] || 0}</span></div></div>
              <div className="col">
                <div className="card text-center text-dark bg-warning">Moderate <span style={bigNumber}>{siteStats["severity"]["Moderate"] || 0}</span></div></div>
              <div className="col">
                <div className="card text-center text-dark bg-info">Low <span style={bigNumber}>{siteStats["severity"]["Low"] || 0}</span></div></div>
              <span id="compare-label">How does this compare?</span>
              <div className="col-12 col-sm-8 offset-sm-2">
                <ul aria-labelledby="compare-label">
                  <li>
                    {myHighBlockers} Your Total Blocker/High issues
                  </li>
                  <li>
                    {Number.parseFloat(averageHighBlockers).toPrecision(3)} Average Blocker/High issues</li>
                </ul>
              </div>
            </div>
            <h2>Issue Breakdown</h2><div className="form-check">
              <input
                className="form-check-input"
                name="criticalOnly"
                type="checkbox"
                checked={this.criticalOnly}
                onChange={this.handleInputChange} />
              <label className="form-check-label" htmlFor="criticalOnly">
                Only show Critical and High issues <span className="visually-hidden"> in chart</span>
              </label>
            </div>
            <hr />

            <IssueChart data={this.filterData(this.state.criticalOnly, siteIssues)} />



            <h3>Blockers</h3>
            {blockers.length == 0 && "We found no blocking issues with this application"}
            {blockers.length > 0 &&
              <ul>
                {blockers.map(issue => (
                  <li> {issue.Summary}</li>
                ))}
              </ul>}


            <footer>
              For more information, visit <a href="https://afb.org/consulting">AFB.org/consulting</a>
            </footer>
          </div>
        </section>
      </article>
    )
  }
}
export default basicTemplate